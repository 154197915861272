<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">{{ item.id !== null ? "Modifier le code de réduction" : "Nouveau code de réduction" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.label"
                    type="text"
                    label="Libellé"
                    :rules="[rules.label]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.code"
                    label="Code"
                    type="text"
                    :rules="[rules.code]"
                    :readonly="item.id !== null"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.value"
                    type="number"
                    label="Valeur"
                    :rules="[rules.value]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.init_quantity"
                    label="Quantité initiale"
                    type="number"
                    :rules="[rules.init_quantity]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.quantity_per_user"
                    type="number"
                    label="Quantité par utilisateur"
                    :rules="[rules.quantity_per_user]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="item.is_percent"
                    :items="responses"
                    item-text="text"
                    item-value="value"
                    label="En pourcentage?"
                    dense
                    clearable
                    hide-details="auto"
                    :rules="[rules.is_percent]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-menu
                    ref="menuref"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.end_date"
                        label="Date de fin"
                        v-bind="attrs"
                        :rules="[rules.end_date]"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="item.end_date"
                      :min="new Date().toISOString()"
                      color="primary"
                      @input="menu = false"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ item.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, ref, watch,
  onMounted,
} from '@vue/composition-api'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import { formatDate } from '@core/date/dateFunctions'
import DatePickerCustom from '@core/components/datepicker/DatePickerCustom'
import store from '@/store'

export default {
  name: 'ReductCodeForm',
  components: { DatePickerCustom },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    object: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)

    const menu = ref(false)
    const color = ref(null)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const responses = ref([
      { text: 'OUI', value: true },
      { text: 'NON', value: false },
    ])
    const rules = {
      label: true,
      code: true,
      value: true,
      init_quantity: true,
      quantity_per_user: true,
      is_percent: true,
      end_date: true,
    }

    watch(menu, val => {
      // eslint-disable-next-line no-return-assign,no-unused-expressions

      // val && setTimeout(() => (picker.value.activePicker = 'DATE'), 500)
    })

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const instance = getCurrentInstance()

    const close = () => {
      message.value = ''
      rules.label = true
      rules.code = true
      rules.end_date = true
      rules.value = true
      rules.init_quantity = true
      rules.quantity_per_user = true
      rules.end_date = true
      isAlertVisible.value = false
      instance.emit('clickout')
    }

    const save = d => {
      menuref.value.save(d)
    }

    const submitForm = () => {
      isAlertVisible.value = false

      isDialogVisible.value = true
      if (item.value.id !== null) {
        store.dispatch('reductCode/updateReductCode', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
            rules.code = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
            rules.value = error.response.data.errors.value !== undefined ? error.response.data.errors.value[0] : true
            rules.init_quantity = error.response.data.errors.init_quantity !== undefined ? error.response.data.errors.init_quantity[0] : true
            rules.quantity_per_user = error.response.data.errors.quantity_per_user !== undefined ? error.response.data.errors.quantity_per_user[0] : true
            rules.is_percent = error.response.data.errors.is_percent !== undefined ? error.response.data.errors.is_percent[0] : true
            rules.end_date = error.response.data.errors.end_date !== undefined ? error.response.data.errors.end_date[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('reductCode/addReductCode', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
            rules.code = error.response.data.errors.code !== undefined ? error.response.data.errors.code[0] : true
            rules.value = error.response.data.errors.value !== undefined ? error.response.data.errors.value[0] : true
            rules.init_quantity = error.response.data.errors.init_quantity !== undefined ? error.response.data.errors.init_quantity[0] : true
            rules.quantity_per_user = error.response.data.errors.quantity_per_user !== undefined ? error.response.data.errors.quantity_per_user[0] : true
            rules.is_percent = error.response.data.errors.is_percent !== undefined ? error.response.data.errors.is_percent[0] : true
            rules.end_date = error.response.data.errors.end_date !== undefined ? error.response.data.errors.end_date[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
    }

    return {
      close,
      responses,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      menu,
      picker,
      menuref,
      item,
      color,
      save,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },

}
</script>

<style scoped>

</style>
